import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngBlockChainGamedevPage = loadable(() => import("../components/BlockChainGamedevPage/english.js"));
const ArabicBlockChainGamedevPage = loadable(() => import("../components/BlockChainGamedevPage/arabic.js"));
const ChineseBlockChainGamedevPage = loadable(() => import("../components/BlockChainGamedevPage/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-9.webp",
            "name": "Leading Blockchain Game Development Company",
            "description": "Step into the billion-dollar industry with the top blockchain game development company to stand out in the secondary market with our best services & solutions.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "127"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is Blockchain gaming Industry?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Games developed on blockchain frameworks or games combined with <b>blockchain technology</b> to support NFT creation, trading, and ownership capabilities are referred to as blockchain gaming. Combining the two realms of blockchain and gaming has given the gaming industry new options.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Can you build a game on the Blockchain?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Yes, you can build games on blockchain that will be more secure, reliable, and transparent than traditional games. <b>Softtik Technologies</b> can help you develop your game.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Does Blockchain gaming make money?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Blockchain games let users explore virtual worlds, compete against one another, and accumulate precious NFTs. You may receive crypto awards as your skill level rises, and you can even sell NFTs on secondary markets to transform your gaming into cryptocurrency.</p>"
                }
            }
            ]
        }
    ]
}

export class BlockChainGamedevPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "English"
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');
        if (region == 'ae') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/blockchain-game-development-services/"
                        title="Blockchain Game Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-9.webp"
                        description="Step into the billion-dollar industry with the top blockchain game development company to stand out in the secondary market with our best services & solutions."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicBlockChainGamedevPage />
                                    : lang == "China"
                                        ? <ChineseBlockChainGamedevPage />
                                        : <EngBlockChainGamedevPage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default BlockChainGamedevPage;